<template>
    <div class="page-content">
        <h2 class="page-title">{{$store.state.language.psd.title}}</h2>
        <p>
            {{$store.state.language.psd.p1}}
        </p>
        <p>
            {{$store.state.language.psd.p2}}
        </p>
        <div class="pl-4 mt-4">
            <ul>
                <li>
                    <p>
                        {{$store.state.language.psd.p3}}
                    </p>
                    <div class="pl-4">
                        <ul>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p4}}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p5}}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p6}}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p7}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <p>
                        {{$store.state.language.psd.p8}}
                    </p>
                    <div class="pl-4">
                        <ul>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p9}}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p10}}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p11}}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{$store.state.language.psd.p12}}
                                </p>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PSA'
}
</script>
<style scoped>
    .page-content{
        max-width: 1300px;
        margin: 100px auto;
        padding: 0px 25px;
    }
    .page-title{
        font-size: 40px;
        font-weight: 900;
        margin-bottom: 25px;
        letter-spacing: 5px;
        text-shadow: -2px 0px 0px rgb(251 188 17 / 0%);
    }
    .page-content p{
        color: rgba(29,29,36,.75);
        font-size: 19px;
        line-height: 30px;
        margin-bottom: 0;
    }
</style>